<template>
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}用户`"
        :footer="null"
        @cancel="handleCancel"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="用户名"
                name="username"
                :rules="[{ required: true, message: '请输入用户名' }, { validator: checkUsername }]"
            >
                <a-input
                    v-model:value="formData.username"
                    :disabled="upDateId ? true : false"
                    show-count
                    :maxlength="100"
                />
            </a-form-item>

            <a-form-item
                label="工号"
                name="jobNo"
                :rules="[{ required: true, message: '请输入工号' }, { validator: checkJobNo }]"
            >
                <a-input v-model:value="formData.jobNo" show-count :maxlength="100" />
            </a-form-item>

            <a-form-item
                label="姓名"
                name="displayName"
                :rules="[
                    { required: true, message: '请输入姓名' },
                    { validator: checkDisplayName },
                ]"
            >
                <a-input v-model:value="formData.displayName" show-count :maxlength="100" />
            </a-form-item>

            <a-form-item
                label="部门-岗位"
                name="deptAndPostInfos"
                :rules="[
                    { required: true, message: '请先选择【主次-部门-岗位】，然后点击右侧+以添加' },
                ]"
            >
                <a-form-item>
                    <a-input-group compact>
                        <Select
                            style="width: 100px"
                            v-model:value="main"
                            :options="[
                                {
                                    label: '主',
                                    value: 1,
                                    disabled: Boolean(
                                        formData.deptAndPostInfos.find(item => item.main)
                                    ),
                                },
                                { label: '次', value: 0 },
                            ]"
                            placeholder="部门主次"
                        />
                        <a-tree-select
                            v-model:value="deptId"
                            style="width: 210px"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            allow-clear
                            tree-default-expand-all
                            :field-names="{
                                label: 'name',
                                value: 'id',
                            }"
                            :tree-data="deptsCandidate"
                            placeholder="部门"
                        ></a-tree-select>
                        <Select
                            style="width: 153px"
                            v-model:value="postId"
                            :options="postsCandidate"
                            labelKey="name"
                            valueKey="id"
                            placeholder="岗位"
                        />
                        <plus-circle-outlined class="add-btn" @click="addDeptAndPostInfos" />
                    </a-input-group>
                    <a-input-group>
                        <div
                            v-for="(item, index) in formData.deptAndPostInfos"
                            :key="index"
                            class="dept-post-item"
                        >
                            <span>
                                <a-tag color="green" :style="{ opacity: item.main ? 1 : 0 }">
                                    主
                                </a-tag>
                                {{ getNameById(item?.deptId, deptsCandidate) }}
                                -
                                {{ getNameById(item?.postId, postsCandidate) }}
                            </span>

                            <minus-circle-outlined
                                class="minus-btn"
                                @click="minusDeptAndPostInfos(index)"
                            />
                        </div>
                    </a-input-group>
                </a-form-item>
            </a-form-item>

            <a-form-item label="手机号" name="cellphone" :rules="[{ validator: checkPhone }]">
                <a-input v-model:value="formData.cellphone" />
            </a-form-item>

            <a-form-item label="邮箱" name="email" :rules="[{ validator: checkEmail }]">
                <a-input v-model:value="formData.email" show-count :maxlength="100" />
            </a-form-item>

            <a-form-item
                label="用户描述"
                name="description"
                :rules="[{ validator: checkDescription }]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item label="角色选择" name="roleIds">
                <Select
                    v-model:value="formData.roleIds"
                    :options="rolesCandidate"
                    mode="multiple"
                    labelKey="name"
                    valueKey="id"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 6 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { apiUpdateUser, apiUpdateUserIndexData } from "@/api";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        PlusCircleOutlined,
        MinusCircleOutlined,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            deptsCandidate: [],
            postsCandidate: [],
            rolesCandidate: [],
            deptId: undefined,
            postId: undefined,
            main: undefined,
            formData: {
                id: null,
                username: "",
                displayName: "",
                jobNo: "",
                cellphone: "",
                email: "",
                description: "",
                deptAndPostInfos: [],
                roleIds: [],
            },
        });

        const addDeptAndPostInfos = () => {
            const { main, deptId, postId } = state;
            if (main == undefined) return showMessage("warning", "请选择部门主次");
            if (deptId == undefined) return showMessage("warning", "请选择部门");
            if (postId == undefined) return showMessage("warning", "请选择岗位");
            state.formData.deptAndPostInfos.push({ main, deptId, postId });
            state.main = undefined;
            state.deptId = undefined;
            state.postId = undefined;
        };

        const getNameById = (id, arr) => {
            let name;
            for (let item of arr) {
                if (item?.id == id) {
                    name = item.name;
                    return name;
                } else {
                    if (item?.children && item?.children.length) {
                        name = getNameById(id, item.children);
                        if (name !== undefined && name !== "") {
                            return name;
                        }
                    }
                }
            }
        };

        const minusDeptAndPostInfos = index => {
            state.formData.deptAndPostInfos.splice(index, 1);
        };

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let { username, displayName, jobNo } = state.formData;
            state.formData.username = username?.trim();
            state.formData.displayName = displayName?.trim();
            state.formData.jobNo = jobNo?.trim();
            let res = await apiUpdateUser(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getUpdateUserIndexData = async (id = "") => {
            let res = await apiUpdateUserIndexData(id);
            if (res.status === "SUCCESS") {
                let { deptsCandidate, postsCandidate, rolesCandidate, sysUserEditInfo } = res.data;
                state.deptsCandidate = [deptsCandidate];
                state.postsCandidate = postsCandidate;
                state.rolesCandidate = rolesCandidate;
                Object.keys(state.formData).forEach(key => {
                    if (key === "deptAndPostInfos") {
                        state.formData.deptAndPostInfos = sysUserEditInfo
                            ? sysUserEditInfo.deptInfos.map(item => ({
                                  main: item.main,
                                  deptId: item?.sysDept?.id,
                                  postId: item?.sysPost?.id,
                              }))
                            : [];
                    } else if (key === "roleIds") {
                        state.formData.roleIds = sysUserEditInfo
                            ? sysUserEditInfo.roles.map(item => item.id)
                            : [];
                    } else {
                        state.formData[key] = sysUserEditInfo ? sysUserEditInfo[key] : "";
                    }
                });
            }
            getNameById(105, state.deptsCandidate);
        };

        const checkPhone = async (rule, value) => {
            if (value && !checkValueIsAvailable(value, "phone")) {
                return Promise.reject("手机号不合法");
            }
        };

        const checkEmail = async (rule, value) => {
            if (value && !checkValueIsAvailable(value, "email")) {
                return Promise.reject("邮箱不合法");
            }
        };

        const checkUsername = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            }
            if (checkValueIsAvailable(value, "chinese")) {
                return Promise.reject("不能含有中文");
            } else {
                return Promise.resolve();
            }
        };

        const checkJobNo = (rule, value) => {
            if (checkValueIsAvailable(value, "spaceWith")) {
                return Promise.reject("不能含有空格");
            }
            if (checkValueIsAvailable(value, "chinese")) {
                return Promise.reject("不能含有中文");
            } else {
                return Promise.resolve();
            }
        };

        const checkDisplayName = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const checkDescription = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getUpdateUserIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            addDeptAndPostInfos,
            minusDeptAndPostInfos,
            getNameById,
            checkPhone,
            checkEmail,
            checkUsername,
            checkJobNo,
            checkDisplayName,
            checkDescription,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-row .ant-form-item) {
    margin-bottom: 0px;
}
:deep(.ant-tag) {
    padding: 1px 3px;
    font-size: 10px;
    line-height: 13px;
    margin-right: 3px;
}
.add-btn {
    color: rgba(0, 0, 0, 0.85);
    position: absolute;
    right: -20px;
    top: 8px;
    cursor: pointer;
}
.dept-post-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    .minus-btn {
        color: rgba(0, 0, 0, 0.85);
    }
}
</style>
