<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="eventUpdateOk" />
        <FilterBlock :userIndexData="userIndexData" @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                :loading="loading"
                bordered
                size="small"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1800 }"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'deptInfos'">
                        <div v-for="(item, index) in record.deptInfos" :key="index">
                            <a-tag :style="{ opacity: item.main ? 1 : 0 }" color="green">主</a-tag>
                            {{ item.sysDept?.name }} - {{ item.sysPost?.name }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'roles'">
                        <div v-for="item in record.roles" :key="item.id">{{ item.name }},</div>
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => openUpDateModal(record.id)" v-perm="'btn:user-edit'">
                                编辑
                            </a>
                            <a @click="resetPassword(record.id)" v-perm="'btn:user-reset-password'">
                                初始化密码
                            </a>
                            <a @click="deleteRow(record.id)" v-perm="'btn:user-delete'">删除</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            type="primary"
                            @click="() => openUpDateModal('')"
                            v-perm="'btn:user-add'"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import { confirmModal, showMessage } from "@/utils/common";
import { apiUserList, apiDeleteUser, apiResetPassword, apiUserEnable } from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "工号",
                dataIndex: "jobNo",
            },
            {
                title: "用户名",
                dataIndex: "username",
            },
            {
                title: "姓名",
                dataIndex: "displayName",
            },
            {
                title: "部门-岗位",
                dataIndex: "deptInfos",
                width: 350,
            },
            {
                title: "手机号",
                dataIndex: "cellphone",
            },
            {
                title: "邮箱",
                dataIndex: "email",
            },
            {
                title: "角色",
                dataIndex: "roles",
            },
            {
                title: "用户描述",
                dataIndex: "description",
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 180,
            },
        ];

        const state = reactive({
            upDateId: null,
            params: null,
            userIndexData: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiUserList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const openUpDateModal = id => {
            state.upDateId = id;
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "用户禁用后，之后将无法在业务流程中使用该用户信息，且该用户名无法登录，是否确认禁用？"
                : "用户启用后，该用户可以在业务流程中使用，且该用户名恢复登录功能，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiUserEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const resetPassword = id => {
            confirmModal({
                type: "info",
                content: "该用户密码将变更为初始密码‘sermatec2022’是否确认初始化密码？",
                onOk: async () => {
                    let res = await apiResetPassword([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "用户删除后将无法恢复，是否确认删除该用户？",
                onOk: async () => {
                    let res = await apiDeleteUser([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        return {
            columns,
            ...toRefs(state),
            openUpDateModal,
            changeSwitch,
            resetPassword,
            deleteRow,
            search,
            paginationChange,
            eventUpdateOk,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tag) {
    padding: 1px 3px;
    font-size: 10px;
    line-height: 13px;
    margin-right: 3px;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
