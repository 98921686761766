<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="jobNo" placeholder="请输入工号" />
            <a-input v-model:value="username" placeholder="请输入用户名" />
            <a-input v-model:value="displayName" placeholder="请输入姓名" />
            <a-tree-select
                v-model:value="deptId"
                style="width: 250px"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                allow-clear
                tree-default-expand-all
                :field-names="{
                    label: 'name',
                    value: 'id',
                }"
                :tree-data="
                    (userIndexData?.deptsCandidate && [userIndexData?.deptsCandidate]) || []
                "
                placeholder="请选择部门"
            ></a-tree-select>
            <Select
                v-model:value="postId"
                :options="userIndexData?.postsCandidate || []"
                labelKey="name"
                valueKey="id"
                placeholder="请选择岗位"
            />
            <a-input v-model:value="cellphone" placeholder="请输入手机号" />
            <a-input v-model:value="email" placeholder="请输入邮箱" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" danger @click="reset">重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { apiSearchUserIndexData } from "@/api";
export default defineComponent({
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const indexData = reactive({
            userIndexData: null,
        });

        const state = reactive({
            jobNo: undefined,
            username: undefined,
            displayName: undefined,
            deptId: undefined,
            postId: undefined,
            cellphone: undefined,
            email: undefined,
        });

        const search = () => {
            let { jobNo, username, displayName, cellphone, email } = state;
            state.jobNo = jobNo?.trim();
            state.username = username?.trim();
            state.displayName = displayName?.trim();
            state.cellphone = cellphone?.trim();
            state.email = email?.trim();
            emit("search", state);
        };

        search();

        const reset = () => {
            Object.keys(state).forEach(key => {
                state[key] = undefined;
            });
            search();
        };

        const getSearchUserIndexData = async () => {
            let res = await apiSearchUserIndexData();
            if (res.status === "SUCCESS") {
                indexData.userIndexData = res.data;
            }
        };
        getSearchUserIndexData();

        return {
            ...toRefs(indexData),
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
